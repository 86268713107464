import GroupIcon from '@material-ui/icons/Group';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AppsIcon from '@mui/icons-material/Apps';
import Chat from '@mui/icons-material/Chat';
import Flag from '@mui/icons-material/Flag';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import polyglotI18nProvider from 'ra-i18n-polyglot';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import russianMessages from 'ra-language-russian';
import * as React from 'react';
import { Admin, Login, Resource } from 'react-admin';
import { ResourceName } from 'types';

import { authProvider, dataProvider } from './providers';
import {
  ApartmentAdComplaintList,
  ApartmentAdComplaintShow,
  ApartmentAdEdit,
  ApartmentAdList,
  ApartmentAdRequestLongRentList,
  ApartmentAdRequestLongRentShow,
  ApartmentAdRequestShortRentList,
  ApartmentAdRequestShortRentShow,
  ApartmentAdShow,
  ApartmentFacilitiesCreate,
  ApartmentFacilitiesEdit,
  ApartmentFacilitiesList,
  ApartmentFacilitiesSubCreate,
  ApartmentFacilitiesSubEdit,
  ApartmentFacilitiesSubList,
  ChatsList,
  ChatsShow,
  ContractCopyShow,
  ContractsCopyList,
  ContractShow,
  ContractsList,
  MessagesShow,
  RejectContractsList,
  ShortsHeadingCreate,
  ShortsHeadingEdit,
  ShortsHeadingList,
  UserComplaintList,
  UserComplaintShow,
  UserIdentityRequestsList,
  UserIdentityRequestsShow,
  UsersEdit,
  UsersList,
  UsersShow,
} from './routes';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const App = () => (
  <Admin i18nProvider={i18nProvider} dataProvider={dataProvider} authProvider={authProvider} loginPage={Login}>
    <Resource
      name={ResourceName.USERS}
      options={{ label: 'Пользователи' }}
      list={UsersList}
      show={UsersShow}
      edit={UsersEdit}
      icon={GroupIcon}
    />
    <Resource
      name={ResourceName.USER_IDENTITY_REQUESTS}
      options={{ label: 'Запросы верификации личности' }}
      list={UserIdentityRequestsList}
      show={UserIdentityRequestsShow}
      icon={HowToRegIcon}
    />
    <Resource
      name={ResourceName.APARTMENT_AD}
      options={{ label: 'Объявления' }}
      list={ApartmentAdList}
      show={ApartmentAdShow}
      edit={ApartmentAdEdit}
      icon={LocalOfferIcon}
    />
    <Resource
      name={ResourceName.APARTMENT_AD_REQUESTS_SHORT_RENT}
      options={{ label: 'Запросы краткосрочная аренда' }}
      list={ApartmentAdRequestShortRentList}
      show={ApartmentAdRequestShortRentShow}
      icon={AccessTimeIcon}
    />
    <Resource
      name={ResourceName.APARTMENT_AD_REQUESTS_LONG_RENT}
      options={{ label: 'Запросы долгосрочная аренда' }}
      list={ApartmentAdRequestLongRentList}
      show={ApartmentAdRequestLongRentShow}
      icon={AccessTimeIcon}
    />
    <Resource
      name={ResourceName.APARTMENT_AD_COMPLAINT}
      options={{ label: 'Жалобы на объявления' }}
      list={ApartmentAdComplaintList}
      show={ApartmentAdComplaintShow}
      icon={Flag}
    />
    <Resource
      name={ResourceName.USER_COMPLAINT}
      options={{ label: 'Жалобы на арендодателя' }}
      list={UserComplaintList}
      show={UserComplaintShow}
      icon={Flag}
    />
    <Resource
      name={ResourceName.CONTRACTS}
      options={{ label: 'Контракты' }}
      list={ContractsList}
      show={ContractShow}
      icon={Flag}
    />
    <Resource
      name={ResourceName.CONTRACTS_REJECT}
      options={{ label: 'Запросы на отмену' }}
      list={RejectContractsList}
      show={ContractShow}
      icon={Flag}
    />
    <Resource
      name={ResourceName.CONTRACTS_COPY}
      options={{ label: 'Контракты ожидания' }}
      list={ContractsCopyList}
      show={ContractCopyShow}
      icon={Flag}
    />
    <Resource name={ResourceName.CHATS} options={{ label: 'Чаты' }} list={ChatsList} show={ChatsShow} icon={Chat} />
    <Resource name={ResourceName.MESSAGES} options={{ label: 'Сообщения' }} show={MessagesShow} icon={Chat} />
    <Resource
      name={ResourceName.APARTMENT_FACILITIES}
      options={{ label: 'Удобства категории' }}
      create={ApartmentFacilitiesCreate}
      edit={ApartmentFacilitiesEdit}
      list={ApartmentFacilitiesList}
      icon={AppsIcon}
    />
    <Resource
      name={ResourceName.APARTMENT_SUB_FACILITIES}
      options={{ label: 'Удобства подкатегории' }}
      create={ApartmentFacilitiesSubCreate}
      edit={ApartmentFacilitiesSubEdit}
      list={ApartmentFacilitiesSubList}
      icon={AppsIcon}
    />
    <Resource
      name={ResourceName.SHORTS_HEADING}
      options={{ label: 'Shorts заголовки' }}
      create={ShortsHeadingCreate}
      edit={ShortsHeadingEdit}
      list={ShortsHeadingList}
      icon={MovieFilterIcon}
    />
  </Admin>
);

export default App;
